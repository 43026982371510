import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";

@Component({
    selector: 'app-upload-form',
    templateUrl: './upload-form.component.html',
    styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent {

    disallowedContentTypes = [
        "application/octet-stream",
        "application/vnd.microsoft.portable-executable",
        ].join(",");

    maxFileSize: number = 10 * 1024 * 1024; // 10 MB

    @Input() form: UntypedFormGroup;
    @Input() mode: 'add' | 'edit' = 'edit';
    @Output() saved: EventEmitter<{ form: UntypedFormGroup, file?: File }> = new EventEmitter<{ form: UntypedFormGroup, file?: File }>();
    @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
    file: File;

    public dropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            alert("Nur eine Datei kann gleichzeit hochgeladen werden.");
            return;
        }

        const droppedFile = files[0];
        if (!droppedFile.fileEntry.isFile) {
            alert("Nur Dateien können hochgeladen werden.");
            return;
        }

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
            if (!this.validateFile(file)) {
                return;
            }

            this.file = file;
        });
    }

    save() {
        this.saved.emit({form: this.form, file: this.file});
    }

    validateFile(file: File) {
        if (file.size > this.maxFileSize) {
            alert(`Datei darf nicht grösser als 10 MB sein.`);
            return false;
        }

        // Validate file type
        if (this.disallowedContentTypes.includes(file.type)) {
            alert(`Binary Dateien sind nicht erlaubt.`);
            return false;
        }

        return true;
    }
}
