import {Component, Input} from '@angular/core';
import {UploadsDataService} from "../../services/uploads-data.service";
import {UploadRecord} from "../../models/uploadRecord.model";
import * as fileSaver from "file-saver";
import {ConfirmationService} from "primeng/api";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
    selector: 'app-upload-list',
    templateUrl: './upload-list.component.html',
    styleUrls: ['./upload-list.component.scss']
})
export class UploadListComponent {
    showForm: boolean;
    showPreview: boolean;
    previewRecord: UploadRecord | undefined;
    previewUrl: SafeResourceUrl | undefined;
    form: UntypedFormGroup;
    formMode: 'add' | 'edit';
    dialogKey: string;

    @Input() uploads: UploadRecord[];
    @Input() onCreated: (description: string, file: File) => Observable<UploadRecord>;

    constructor(private fb: UntypedFormBuilder,
                private confirmationService: ConfirmationService,
                private readonly sanitizer: DomSanitizer,
                private uploadsDataService: UploadsDataService) {
        this.dialogKey = Date.now().toString()
    }

    add() {
        this.formMode = 'add';
        this.form = this.fb.group({
            description: ['', Validators.required]
        });
        this.showForm = true;
    }

    edit(uploadRecord: UploadRecord) {
        this.formMode = 'edit';
        this.form = this.fb.group({
            id: ['', Validators.required],
            description: ['', Validators.required],
            fileName: ['', Validators.required]
        });
        this.form.patchValue(uploadRecord);
        this.showForm = true;
    }

    preview(uploadRecord: UploadRecord) {
        this.uploadsDataService.getUpload(uploadRecord).subscribe(blob =>
            {
                this.previewRecord = uploadRecord;
                this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
                this.showPreview = true;
            }
        );
    }

    showDeleteDialog(uploadRecord: UploadRecord) {
        this.confirmationService.confirm({
            key: this.dialogKey,
            header: 'Upload löschen?',
            message: `Möchten Sie den Upload ${uploadRecord.fileName} wirklich löschen?`,
            accept: () => {
                this.uploadsDataService.deleteUpload(uploadRecord.id)
                    .subscribe(() => {
                        this.uploads = this.uploads.filter(f => f.id != uploadRecord.id)
                    });
            }
        });
    }

    download(uploadRecord: UploadRecord) {
        this.uploadsDataService.getUpload(uploadRecord)
            .subscribe(blob => {
                fileSaver.saveAs(blob, `${uploadRecord.fileName}`);
            });
    }

    savedForm(event: any) {
        this.showForm = false;
        const uploadRecord = event.form.value;
        if (this.formMode === 'edit') {
            this.uploadsDataService.updateUpload(uploadRecord)
                .subscribe(() => {
                    this.uploads = this.uploads.map(r => r.id !== uploadRecord.id ? r : { ...r, ...uploadRecord });
                });
        } else {
            this.onCreated(uploadRecord.description, event.file)
                .subscribe((res) => {
                    this.uploads.push(res);
                });
        }
    }

    isPreviewable(record: UploadRecord) {
        return this.isPdf(record) || this.isImage(record);
    }

    isPdf(record: UploadRecord): boolean {
        return record.contentType == "application/pdf";
    }

    isImage(record: UploadRecord): boolean {
        return record.contentType.startsWith("image/");
    }
}
