<div class="container-fluid">

    <div class="row" [formGroup]="form">
        <div class="col-md-12">
            <div class="form-group">
                <label class="form-grid-item" for="description">Bezeichnung*</label>
                <input type="text" placeholder="Name" class="form-control form-grid-item" formControlName="description" id="description">
                <div class="input-error" *ngIf="form.get('description').hasError('required') && form.get('description').touched">Bezeichnung wird benötigt</div>
            </div>
            <div class="form-group">
                <div *ngIf="mode === 'add'" class="center margin-b-20">
                    <ngx-file-drop dropZoneLabel="Datei hier ablegen" (onFileDrop)="dropped($event)"
                                   [multiple]="false" [directory]="false" contentClassName="ngx-file-drop-content" dropZoneClassName="ngx-file-drop-zone">
                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                            <i class="fa fa-2x fa-arrow-circle-o-up" aria-hidden="true"></i>&nbsp;Datei hier ablegen, oder&nbsp;<a class="cursor-pointer" (click)="openFileSelector()">selektieren</a>. Max 10 MB
                        </ng-template>
                    </ngx-file-drop>
                </div>
                <label *ngIf="mode === 'edit'" class="form-grid-item" for="fileName">Dateiname*</label>
                <label *ngIf="mode === 'add' && file" class="form-grid-item" for="fileName">Ausgewählte Datei*</label>
                <input *ngIf="mode === 'add' && file" type="text" class="form-control form-grid-item"  disabled [value]="file.name">
                <input *ngIf="mode === 'edit'" type="text" class="form-control form-grid-item" formControlName="fileName" id="fileName">
                <div class="input-error" *ngIf="mode === 'edit' && form.get('fileName').hasError('required') && form.get('description').touched">Dateiname wird benötigt</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button (click)="canceled.emit()" class="btn btn-default"><i class="fa fa-long-arrow-left"></i>&nbsp;Zurück</button>&nbsp;
            <button (click)="save()" class="btn btn-primary" [disabled]="!form.valid || !form.dirty || (mode === 'add' && !file)"><i class="fa fa-save"></i>&nbsp;Speichern</button>
        </div>
    </div>

</div>
